:root {
  --play: #4dd341;
  --play-dark: #37ba2c;
}

.play-button {
  padding: 16px 42px;
  box-shadow: 0px 0px 6px -6px rgba(0, 0, 0, 0.5);
  line-height: 1.25;
  border-radius: 12px;
  background: var(--play);
  text-decoration: none;

  transition: background-color 0.3s ease;
}

.play-button:hover {
  background: var(--play-dark);
}

.play-button:active:after {
  width: 300px;
  height: 300px;
}

.load-font {
  min-width: 100px;
  line-height: 47px;
}
