:root {
  --play: #4dd341;
  --play-dark: #37ba2c;
}

.play-button {
  background: var(--play);
  border-radius: 12px;
  padding: 16px 42px;
  line-height: 1.25;
  text-decoration: none;
  transition: background-color .3s;
  box-shadow: 0 0 6px -6px #00000080;
}

.play-button:hover {
  background: var(--play-dark);
}

.play-button:active:after {
  width: 300px;
  height: 300px;
}

.load-font {
  min-width: 100px;
  line-height: 47px;
}

/*# sourceMappingURL=index.d7295d63.css.map */
